import '@/styles/tailwind.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { config } from '@fortawesome/fontawesome-svg-core';
import type { ComponentProps, PropsWithChildren, ReactNode } from 'react';

import type { RouteParams } from '@/app/(types)/types';

interface Props extends PropsWithChildren, Pick<RouteParams, 'locale'> {
  bodyProps?: ComponentProps<'body'>;
  head?: ReactNode;
  headProps?: ComponentProps<'head'>;
  htmlProps?: ComponentProps<'html'>;
}

config.autoAddCss = false;

export function RootLayoutTemplate(props: Props) {
  const { bodyProps, children, head, headProps, htmlProps, locale } = props;

  return (
    <html lang={locale} {...htmlProps}>
      <head {...headProps}>
        <meta name="robots" content="noindex" />
        {head}
      </head>

      <body
        {...bodyProps}
        suppressHydrationWarning={
          // TODO remove suppressHydrationWarning later
          //  temporary disable hydration warning to avoid spamming Sentry/Rollbar. We should fix hydration errors. Example: https://github.com/Kaligo/rewards-central/pull/1907
          true
        }
      >
        {children}
      </body>
    </html>
  );
}
